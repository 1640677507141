import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import Main from '../components/opus-segment-studio/opusSSMainContent';

const OpusSegmentStudio = ({ location }) => (
  <Layout pageTitle="Opus Segment Studio" location={location}>
    <Main />
  </Layout>
);

OpusSegmentStudio.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default OpusSegmentStudio;
