import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Hero from './opusSSHero';
import WistiaVideo from '../wistiaVideo';

/**
 * Opus Segment Studio page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = () => {
  const {
    contentfulOpusSegmentStudio: {
      copy: {
        childMarkdownRemark: { html },
      },
      title,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulOpusSegmentStudio {
        copy {
          childMarkdownRemark {
            html
          }
        }
        title
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.contactMain,
        main.cahpsPlaybookMain
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.contactInner
        )}
      >
        <Hero content={html} title={title} />
        <div
          className={cx(
            base.absoluteFill,
            base.flex,
            base.itemsCenter,
            base.justifyCenter,
            base.pb3,
            base.w100
          )}
        >
          {process.env.NODE_ENV === 'production' && (
            <WistiaVideo videoID="e6sf9l13h9" />
          )}
        </div>
      </div>
    </section>
  );
};

export default Main;
